export default class Product {
    public productId: number = 0;
    public description: string = '';
    public discountAmount: number = null;
    public discountPercentage: number = null;
    public productType: string = '';
    public productTypeId: number = 0;
    public siteIds: number[] = [];
    public amountInStock: number = null;
    public optional: boolean = false;
    public indexable: boolean = false;
    public vat: any = null;
    public vatId: number = null;
    public registerApplicable: boolean = false;
    public financeAccountId: number;
    public financeAccount: any;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
