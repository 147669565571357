import { productService, dictionaryHelper, bookingService, financeAccountService } from '@/main';
import Component from 'vue-class-component';
import to from 'await-to-js';
import Product from '../../models/Product';
import PriceRule from '../../models/PriceRule';
import { BModal } from 'bootstrap-vue';
import { $router } from '@/router';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';
import { Watch } from 'vue-property-decorator';
import BasePage from '@/models/BasePage';
import { ProductTypeEnum } from '@/models/ProductTypeEnum';
import { sitesModule } from '@/store/modules/site';
const mustHaveType = (value) => value !== ProductTypeEnum.Unknown && value !== '';

@Component({
    mixins: [validationMixin],
    components: {},
    validations: {
        selectedSites: { required },
        product: {
            description: { required },
            productType: { mustHaveType },
            vatId: { required },
            financeAccount: { financeAccountId: { required } },
        },
    },
})
export default class ProductComponent extends BasePage implements IVuelidate<any> {
    public product: Product = {} as Product;
    public isLoading: boolean = true;
    public editMode: boolean = false;
    public productTypes: any[] = null;
    public dictionary = dictionaryHelper;
    public priceRules: PriceRule[] = [];
    public VATs: any[] = [];
    public travelTypes: any[];
    public financeAccounts: any[] = [];
    public productTypeIdObject: any = null;

    public $refs: {
        approveModal: BModal;
        noticeModal: BModal;
    };

    @Watch('productTypeIdObject')
    public onProductTypeIdObjectChange(val, oldVal) {
        if (val) {
            this.product.productTypeId = val.id;
            this.product.productType = val.name;
        }
    }

    @Watch('product.vat')
    public onVatObjectChange(val, oldVal) {
        if (val) {
            this.product.vat = val;
            this.product.vatId = val.vatId;
        }
    }

    @Watch('product.financeAccount')
    public onFinAccObjectChange(val, oldVal) {
        if (val) {
            this.product.financeAccountId = val.financeAccountId;
        }
    }

    public async created() {
        await sitesModule.fetchSitesIfNeeded();
        await this.loadProductTypes();
        await this.loadVATs();
        await this.getTravelTypes();
        await this.loadFinanceAccounts();

        this.product = await this.getProduct();

        if (this.product.productId) {
            await this.loadRelatedPriceRules(this.product.productId);
        }
        this.isLoading = false;
    }

    public async getTravelTypes(): Promise<any[]> {
        return (this.travelTypes = bookingService.getTravelGroupTypes());
    }

    public async loadProductTypes() {
        const [err, response] = await to(productService.getProductTypes());
        if (err) {
            this.showError('Producttypen ophalen mislukt');
        }

        this.productTypes = response.data.filter((type) => type.id > 0);
        this.productTypes.forEach((productType) => (productType.translation = dictionaryHelper.get('ProductTypes')[productType.name]));
    }

    public async loadFinanceAccounts() {
        const [err, response] = await to(financeAccountService.getAccounts(this.site.siteId));
        if (err) {
            this.showError('Grootboekrekeninge ophalen mislukt');
        }
        return (this.financeAccounts = response.data);
    }

    public async loadVATs() {
        const [err, response] = await to(productService.getVATs());
        if (err) {
            this.showError('Producttypen ophalen mislukt');
        }
        return (this.VATs = response.data);
    }

    public async loadRelatedPriceRules(productId: number) {
        const [err, response] = await to(productService.getRelatedPriceRules(productId, this.site.siteId));
        if (err) {
            this.showError('Gerelateerde prijsregels ophalen mislukt');
        }

        this.priceRules = response.data;
    }

    public async getProduct() {
        const newProduct = {
            discountAmount: null,
            discountPercentage: null,
            productId: null,
            productType: null,
            description: '',
            amountInStock: null,
            productTypeId: null,
            siteIds: [],
            optional: false,
        } as Product;

        newProduct.siteIds = [this.site.siteId];

        if (this.$route.params.productId === 'nieuw') {
            this.editMode = true;
            return newProduct;
        }

        const [err, response] = await to(productService.getProduct(this.$route.params.productId, this.site.siteId));
        if (err) {
            this.showError('Product ophalen mislukt');
        }

        const product = new Product(response.data);
        const productType = this.productTypes.find((type) => type.name === product.productType);
        if (productType) {
            this.productTypeIdObject = productType;
            product.productTypeId = productType.id;
        }

        const financeAccount = this.financeAccounts.find((acc) => acc.financeAccountId === product.financeAccountId);
        if (financeAccount) {
            product.financeAccount = financeAccount;
        }

        const vat = this.VATs.find((vatObj) => vatObj.vatId === product.vatId);
        if (vat) {
            product.vat = vat;
        }

        return (this.product = product);
    }

    public async edit() {
        this.editMode = true;
    }

    public async cancelEdit() {
        this.editMode = false;
    }

    public async save() {
        let err: any;
        let response: any;

        if (!this.validateObject('product')) {
            return this.clearAndShowError('Niet alle verplichte velden zijn ingevuld.');
        }

        if (this.product.discountAmount && this.product.discountPercentage) {
            return this.showValidationErrors(['Percentage en bedrag tegelijk opslaan is niet mogelijk. Kies een van beide.']);
        }

        if (this.product.vatId) {
            this.product.vat = this.VATs.find((VAT) => VAT.vatId === this.product.vat.vatId);
        }

        this.showPending('Product opslaan..');

        if (this.product.productId) {
            [err, response] = await to(productService.updateProduct(this.product, this.site.siteId));
        } else {
            [err, response] = await to(productService.saveProduct(this.product, this.site.siteId));
        }

        if (err) {
            this.showError('Product opslaan mislukt');
        }

        this.product.productId = response.data.productId;
        this.editMode = false;
        this.clearAndShowSuccess('Product opgeslagen');
        this.$refs.approveModal.hide();
        $router.push({ name: 'site-products', params: { siteId: this.siteId.toString(), siteKey: this.siteKey } });
    }

    public saveWithApproveModal() {
        this.$refs.approveModal.show();
    }

    public async copy() {
        if (this.product.discountAmount && this.product.discountPercentage) {
            return this.showValidationErrors(['Percentage en bedrag tegelijk opslaan is niet mogelijk. Kies een van beide.']);
        }

        if (this.product.vat.vatId) {
            this.product.vat = this.VATs.find((VAT) => VAT.vatId === this.product.vat.vatId);
        }

        this.product.productId = null;
        this.product.siteIds = [this.site.siteId];

        const [err, response] = await to(productService.copyProduct(this.product));
        if (err) {
            this.showError('Product kopieren mislukt');
        }

        this.product.productId = response.data.productId;
        this.editMode = false;
        this.showSuccess('Product gekopieerd');

        this.$router.push({ name: 'site-products', params: { siteId: this.site.siteId.toString(), siteKey: this.site.siteKey } });
        this.$refs.approveModal.hide();
    }

    public async selectProductType(id) {
        const selectedProduct = this.productTypes.find((productType) => {
            return productType.id === parseInt(id, 10);
        });

        this.product.productTypeId = selectedProduct.id;
        this.product.productType = selectedProduct.name;
    }

    public goToRule(rule: PriceRule) {
        $router.push({
            name: 'site-price-rule',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, priceRuleKey: rule.priceRuleId.toString() },
        });
    }

    public getFinanceAccountName() {
        const finAcc = this.financeAccounts.find((x) => x.financeAccountId === this.product.financeAccountId);

        return finAcc ? finAcc.name : 'n.v.t';
    }

    public getVatName() {
        const vatObj = this.VATs.find((vat) => vat.vatId === this.product.vatId);

        return vatObj ? vatObj.description : 'n.v.t';
    }
}
